 import React, {Component} from 'react'
import NavigationBottomBar from "../../layout/bottomBar/NavigationBottomBar";
import {Header} from "../../layout/header/Header";
import './CreationSeancePage.scss'
import CreationSeanceForm, {SeriesFormProps} from "./components/CreationSeanceForm";
import {connect} from "react-redux";
import {parseWorkout} from "../../../core/workout/parser/workoutParser";
import {SubmissionError} from "redux-form";
 import {history} from "../../../store/configureStore";
 import AddFavouriteModal from "../../components/favourites/AddFavouriteModal";

class CreationSeancePage extends Component<any> {
  state = {
    openAddFavouriteModal: false,
    currentWorkout: '',
    initialValues: {
      nbSeries: "3",
      restSeries: "60",
      exercises: [{
        type: "-1",
        effort: "30",
        rest: "30"
      }]
    }
  }

  UNSAFE_componentWillMount() {
    const urlSearchParam = new URLSearchParams(this.props?.location?.search)
    const workoutExpression = urlSearchParam?.get('workout')
    if (workoutExpression) {
      const series = parseWorkout(workoutExpression)
      if (series.length > 0) {
        this.setState({
          initialValues: {
            nbSeries: series.length,
            restSeries: series[0].rest,
            exercises: series[0].exercises.map(e => ({
              type: e.type,
              rest: e.rest,
              effort: e.effort
            }))
          }
        })
      }
    }
  }

  convertToWorkoutExpression = (values: SeriesFormProps): string => {
    let expressionWorkout = `s${values.nbSeries}r${values.restSeries}`
    if (values.exercises && values.exercises.length > 0) {
      expressionWorkout = expressionWorkout
        + '-'
        + values.exercises
          .map(e => `e${e.effort}r${e.rest}` + (e.type && e.type !== "-1" ? `t${e.type}` : ''))
          .join('-')
    } else {
      throw new SubmissionError({ _error: 'Ajoutez au moins un exercice' })
    }
    return expressionWorkout
  }

  handleSubmit = (values: SeriesFormProps) => {
    const expressionWorkout = this.convertToWorkoutExpression(values)
    history.push(`/workout/${expressionWorkout}`)
  }

  addToFavourite = (values: SeriesFormProps) => {
    const expressionWorkout = this.convertToWorkoutExpression(values)
    this.setState({
      currentWorkout: expressionWorkout
    })
    this.handleOpenFavouriteModal()
  }

  handleOpenFavouriteModal = () => {
    this.setState({
      openAddFavouriteModal: true
    })
  }

  handleCloseFavouriteModal = () => {
    this.setState({
      openAddFavouriteModal: false
    })
  }

  render() {
    const {initialValues} = this.state
    console.log(initialValues)
    return (
      <div className="creation-seance-page">
        <NavigationBottomBar />
        <Header thin />
        <main className="main">
          <AddFavouriteModal
            handleClose={this.handleCloseFavouriteModal}
            open={this.state.openAddFavouriteModal}
            currentWorkout={this.state.currentWorkout}
          />
          <section className="section-creation-seance">
            <h2>Séance d'entrainement</h2>
            <CreationSeanceForm
              addToFavourite={this.addToFavourite}
              onSubmit={this.handleSubmit}
              initialValues={initialValues}/>
          </section>
        </main>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
})

export default connect(
  mapStateToProps,
  {
  }
)(CreationSeancePage)

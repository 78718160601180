import React from "react";

export const Loader = () => (
  <svg width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
    <g>
      <path d="M50 24A26 26 0 1 0 76 50.00000000000001" fill="none" stroke="#ffffff" strokeWidth="11"></path>
      <path d="M49 10L49 38L63 24L49 10" fill="#FFFFFF"></path>
      <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
    </g>
  </svg>

)

import {Favourite} from "./favourites";
import {getFavourites} from "./favouritesSelector";
import {storeFavourites} from "../../storage/LocalStorage";

const ACTION_NAMESPACE = '/FAVOURITES'

export const types = {
	ADD_FAVOURITE: `${ACTION_NAMESPACE}/ADD_FAVOURITE`,
  SET_FAVOURITES: `${ACTION_NAMESPACE}/ADD_FAVOURITES`
}


export const addFavourite = (favourite: Favourite) => (dispatch: any, getState: any) => {
  const favourites = getFavourites(getState())
  const newList: Favourite[] = [
    favourite,
    ...favourites
  ]
  dispatch({
    type: types.SET_FAVOURITES,
    payload: newList
  })
  storeFavourites(newList)
}

export const renameFavourite = (favourite: Favourite, newName: string) => (dispatch: any, getState: any) => {
  const favourites = getFavourites(getState())
  const newList: Favourite[] = favourites.map(f => {
    if (f.guid === favourite.guid) {
      return {
        ...favourite,
        name: newName
      }
    }
    return f
  })
  dispatch({
    type: types.SET_FAVOURITES,
    payload: newList
  })
  storeFavourites(newList)
}

export const removeFavourite = (favourite: Favourite) => (dispatch: any, getState: any) => {
  const favourites = getFavourites(getState())
  const newList: Favourite[] = favourites.filter(f => f.guid !== favourite.guid)
  dispatch({
    type: types.SET_FAVOURITES,
    payload: newList
  })
  storeFavourites(newList)
}

export const initFavourites = (favourites: Favourite[]) => (dispatch: any) => {
  dispatch({
    type: types.SET_FAVOURITES,
    payload: favourites
  })
}

import React, {Component} from 'react'
import {parseWorkout} from "../../../core/workout/parser/workoutParser";
import {computeTimelineDuration, convertSeriesToTimeline} from "../../../core/timeline/timeline-utils";
import {formatTime} from "../../../core/time-formater";
import './Homepage.scss'
import gainagesImg from '../../../resources/img/homepage/gainage.png'
import sautImg from '../../../resources/img/homepage/saut.png'
import NavigationBottomBar from "../../layout/bottomBar/NavigationBottomBar"
import {Header} from "../../layout/header/Header"
import { Link } from 'react-router-dom'

export default class HomePage extends Component<any> {
  seances = [
    { expression: 's3R60-e30tag-e30tagld-e30r15taglg-e30r15tjs-e30tdg', label: 'Découvrir la séance' },
    { expression: 's3R60-e20r10tcjj-e20r10tspm-e20r10tjfm-e20r10tagep-e20tcb', label: 'Découvrir la séance' }
  ].map(({expression, label}) => {
    const series = parseWorkout(expression)
    const timeline = convertSeriesToTimeline(series)
    const duration = computeTimelineDuration(timeline)
    return {
      url: `/workout/${expression}`,
      label,
      duration: formatTime(duration)
    }
  })

  render() {
    const seanceGainage = this.seances && this.seances[0]
    const seanceCardio = this.seances && this.seances[1]

    return (
      <div className="homepage">
        <NavigationBottomBar />
        <Header />
        <main className="main">
          <section className="section section-introduction">
            <div className="introduction">
              <h2>Timez vos entrainements fractionnés</h2>
              <p>
                Créez vos <b>séances d'entrainement fractionnés</b> et <b>réalisez les</b> !<br/>
              </p>
              <p>
                <b>L'entrainement fractionné</b> est un incontournable du <b>renforcement musculaire</b>.<br/>
                Il organise l'entrainement sous forme de <b>séries d'exercices</b> alternant entre <b>phases de travail</b> et <b>phases de récupérations</b>.<br/>
                En jouant sur les exercices et leurs durées, il est possible d'adapter la difficulté à son niveau et d'augmenter progressivement la charge.<br/>
                Les exercices peuvent être très variés afin de solliciter l'ensemble des muscles du corps.<br/>
              </p>
              <p>
                Retrouvez <a href="#section-seances">ici quelques exemples de séances</a>.
              </p>
            </div>
            <div className="introduction-illustration">
              <img src={gainagesImg} alt="" />
            </div>
          </section>
          <section className="section section-creation-seance" id="section-creation-seance">
            <div className="create-seance-illustration">
              <img src={sautImg} alt="" />
            </div>
            <div className="create-seance-text">
              <div>
                <h2>Créez votre séance personnalisée</h2>
                <p>Indiquez la liste des exercices que vous souhaitez réaliser (gainage, pompes, squat etc...), le nombre de séries et la durée de récupération entre chaque exercice.
                </p>
                <p>
                  Vous pourrez alors réaliser votre propre séance de fractionnés en ligne !<br />
                  Conservez le lien de votre séance pour la refaire à volonté et même la partager à vos amis !
                </p>
              </div>
              <div className="action-button">
                <Link className="btn" to="/workout/edit">
                  Nouvelle séance
                </Link>
              </div>
            </div>
          </section>
          <section className="section section-seances" id="section-seances">
            <h2>Quelques exemples...</h2>
            <div className="introduction">
              <p>
                Les séances proposées ici servent principalement d'exemples.
                Libre à vous de vous en inspirer pour créer vos propres séances d'entrainement.
              </p>
              <p>
                N'oubliez pas de toujours prendre en compte votre niveau lorsque vous planifiez ce type d'entrainement.
                Une surchage de travail est synonyme de risque de blessure.
                Pensez également à bien vous échauffer avant de lancer une séance.<br/>
                Enfin, si certains exercices vous semblent trop difficiles, vous pouvez les décliner dans une version plus abordable : par exemple, si vous n'êtes pas à l'aise pour faire des pompes, vous pouvez baisser la difficulté en les faisant sur les genoux.<br/>
              </p>
              <p>
                Force et motivation à vous !
              </p>
            </div>
            <div className="list-cards">
              <div className="card">
                <h3>Séance de gainage</h3>
                <p>
                  Le gainage est bien connu des sportifs et athlètes pour tous ses bienfaits sur le corps.
                  Il est idéal pour renforcer les muscles en profondeur et gagner en résistance.
                  Il constitue un complétement essentiel à l'activité physique pour diminuer le risque de blessure.<br/>
                  Voici un exemple de séance qui se concentre sur ce type d'exercices :
                </p>
                <div className="action-container">
                  <Link className="btn-seance" to={seanceGainage.url}>
                    {seanceGainage.label}
                  </Link>
                </div>
              </div>
              <div className="card">
                <h3>Séance de cardio</h3>
                <p>
                  La séance de cardio est idéale pour gagner en dynamisme et même en explosivité selon l'intensité des exercices.
                  Cette séance est souvent conseillée pour la perte de poids.<br/>
                  Selon l'intensité, ce type de séance peut être assez exigeant pour le corps.
                  Prévoyez une période de repos suffisante entre chaque séance pour ne pas accumuler de fatigue et éviter de vous blesser.<br/>
                </p>
                <div className="action-container">
                  <Link className="btn-seance" to={seanceCardio.url}>
                    {seanceCardio.label}
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    )
  }
}

import React from 'react'
import './Header.scss'
import logoImg from "../../../resources/img/logo/chrono.png";
import {Link} from "react-router-dom";

export const Header = ({thin}: any) => {
  return (
    <header className={`header ${thin ? 'thin' : ''}`}>
      <Link className="" to="/home">
        <img src={logoImg} alt='' className="logo" />
      </Link>
      <h1 className="header-title">
        Timer workout
      </h1>
    </header>
  )
}

import React, {Component} from 'react'
import {getToken, postActivity} from "../../../core/webservices/strava-webservices";
import {parseWorkout} from "../../../core/workout/parser/workoutParser";
import {computeTimelineDuration, convertSeriesToTimeline} from "../../../core/timeline/timeline-utils";
import {Loader} from '../../components/Loader'
import './ShareStravaWorkoutPage.scss'
import {history} from "../../../store/configureStore";
import {ProviderContext, withSnackbar} from "notistack";

class ShareStravaWorkoutPage extends Component<any & ProviderContext> {

  componentDidMount() {
    const workoutExpression = this.props?.match?.params?.workout
    try {
      const series = parseWorkout(workoutExpression)
      const timeline = convertSeriesToTimeline(series)
      const workoutDuration = computeTimelineDuration(timeline)
      const urlSearchParam = new URLSearchParams(this.props?.location?.search)
      const code = urlSearchParam?.get('code')
      const scope = urlSearchParam?.get('scope')
      this.sendStravaActivity(workoutExpression, workoutDuration, timeline.length, code, scope)
    } catch (e) {
      history.push(`/workout/${workoutExpression}`)
    }
  }

  toISOLocal(d: Date) {
    var z  = (n: number) =>  ('0' + n).slice(-2);
    var zz = (n: number) => ('00' + n).slice(-3);
    var off = d.getTimezoneOffset();
    var sign = off < 0? '+' : '-';
    off = Math.abs(off);

    return d.getFullYear() + '-'
      + z(d.getMonth()+1) + '-' +
      z(d.getDate()) + 'T' +
      z(d.getHours()) + ':'  +
      z(d.getMinutes()) + ':' +
      z(d.getSeconds()) + '.' +
      zz(d.getMilliseconds()) +
      sign + z(off/60|0) + ':' + z(off%60);
  }

  sendStravaActivity = async (
    workoutExpression: string,
    workoutDuration: number,
    indexEndScreen: number,
    code?: string | null,
    scope?: string | null
  ) => {
    const {enqueueSnackbar} = this.props
    if (code && scope && scope.indexOf('activity:write') !== -1) {
      const token = await getToken(code)
      if (token) {
        await postActivity({
          commute: 0,
          trainer: 0,
          start_date_local: this.toISOLocal(new Date()),
          description: `Séance https://workout.okyear.fr/workout/${workoutExpression}`,
          name: 'Séance de renforcement musculaire',
          elapsed_time: workoutDuration ?? 0,
          type: 'Workout'
        }, token)
        enqueueSnackbar("Séance envoyée", {
          persist: false,
          autoHideDuration: 3000,
          variant: "success",
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          }
        })
        history.push(`/workout/${workoutExpression}?index=${indexEndScreen}`)
      } else {
        console.log('Send activity not allowed')
        history.push(`/workout/${workoutExpression}?index=${indexEndScreen}`)
      }
    } else {
      console.log('Failed to send activity')
      history.push(`/workout/${workoutExpression}?index=${indexEndScreen}`)
    }
  }

  render() {
    return (
      <div className="share-strava-workout">
        <div className="spinner-container-center">
          <Loader />
        </div>
      </div>
    )
  }
}

export default withSnackbar(ShareStravaWorkoutPage)

import React, {FunctionComponent} from "react";
import {ExerciseScores} from "../../../../store/workout/workoutSelector";
import {formatTime} from "../../../../core/time-formater";
import logoImg from "../../../../resources/img/logo/chrono.png";
import './WorkoutScoresScreen.scss'
import {redirectToStrava} from "../../../../core/webservices/strava-webservices";
import stravaLogo from "../../../../resources/img/logo/strava.png";
import {config} from "../../../../core/conf/config";
import winImg from "../../../../resources/img/illustrations/win-dark.png";

type WorkoutScoresScreenProps = {
  workoutExpression: string,
  scores: ExerciseScores[],
  restart: () => void
}

export const WorkoutScoresScreen: FunctionComponent<WorkoutScoresScreenProps> = ({
    workoutExpression,
    scores,
    restart,
  }) => (
  <div className="workout-end">
    <div className="banner-title">
      <button onClick={restart} className="retour-button">
        <i className="fa fa-arrow-left" aria-hidden="true"></i>
        <span className="sr-only">Retour début</span>
      </button>
      Terminé !
    </div>

    <div className="workout-end-body">
      <div className="illustration-win">
        <img src={winImg} alt="" width="100%" />
      </div>
      <div className="description">
        <p>
          Et c'est terminé !!!<br/>
          Bravo ! Vous avez tenu bon, franchement chapeau !
          <br/>
          Bonne récup en attendant votre prochaine session d'entrainement.
        </p>
      </div>
      <button className="strava-share-btn" onClick={() => {
        redirectToStrava(`${config.baseUrl}/workout/strava/${workoutExpression}`)
      }}>
        <div>
          <img src={stravaLogo} alt="" width={32}/> Partager mon activité sur Strava
        </div>
      </button>
    </div>

    {
      scores && (scores.length > 0) &&
      <div className="oblique-container scores-container">
        <table className="scores">
          <tbody>
          {scores && scores.map((s: ExerciseScores, k) => {
            return <tr key={k}>
              <td>
                <div className="score-label">
                  {s.exercise?.label}
                </div>
              </td>
              {
                s.exercise?.label && s.seriesScores.map((seriesScore, indexScore) => {
                  const scoreFormatted = formatTime(seriesScore.score)
                  return (
                    <td key={`${k}-${indexScore}`}>
                      <div className="score-value">
                        <img src={logoImg} alt='' className="timer-logo-xsmall"/> {scoreFormatted}
                      </div>
                    </td>
                  )
                })
              }
            </tr>
          })}
          </tbody>
        </table>
      </div>
    }
  </div>
)

import React, {Component} from 'react'
import {MenuItem, MenuList, Paper, Popover} from "@material-ui/core";

interface OptionMenuItemProps {
  action: () => any | void,
  content: any
}
interface OptionMenuProps {
  menuItems: OptionMenuItemProps[],
  className?: string,
  buttonContent?: any
}

export class OptionMenu extends Component<OptionMenuProps> {
  state = {
    anchorEl: undefined,
  }

  handleListKeyDown = (event: any) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.setState({ open: false });
    }
  }

  handleClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose = () => {
    this.setState({ anchorEl: undefined })
  }

  render() {
    const {anchorEl} = this.state
    const {menuItems, buttonContent, className} = this.props

    const open = Boolean(anchorEl);

    return <>
        <button className={className} onClick={this.handleClick}>
          {buttonContent}
        </button>
        <Popover
          id="share-menu-workout"
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Paper>
            <MenuList className="option-menu-item-list">
              {menuItems && menuItems.map((item: any, index: number): any =>
                <MenuItem key={index} onClick={() => {
                  item.action()
                  this.handleClose()
                }}>
                  {item.content}
                </MenuItem>
              )
              }
            </MenuList>
          </Paper>
        </Popover>
      </>
  }
}

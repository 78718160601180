import React, {Component} from 'react'
import {connect} from 'react-redux'
import {completeExercise, gotoTimelineIndex, pause, play,} from '../../../../store/workout/workoutActions'
import {
  getCurrentRemainingTime,
  getCurrentTimelineItem,
  getCurrentWorkout,
  getPlayerTime,
  getSeries,
  getTimeline,
  getTimelineDuration,
  getTimelineIndex,
  isPlaying,
  isWorkoutEnd
} from '../../../../store/workout/workoutSelector'
import {TimelineItem} from "../../../../core/timeline/types/timeline"
import Timeline from "./Timeline";
import {formatTime} from "../../../../core/time-formater"
import logoImg from "../../../../resources/img/logo/chrono.png"
import {WorkoutPlayer} from './WorkoutPlayer'
import {Link} from 'react-router-dom'
import './WorkoutExercices.scss'
// @ts-ignore
import soundCountDown from '../../../../resources/audio/end.mp3'
import WorkoutPreview from "./WorkoutPreview";
import {Transition} from "react-spring/renderprops";
import {Series} from "../../../../core/workout/types/workout.d.";
import AddFavouriteModal from "../../../components/favourites/AddFavouriteModal";

type WorkoutExercicesProps = {
  currentWorkout: string | null,
  // selector
  isPlaying: boolean,
  series: Series[],
  timeline?: TimelineItem[],
  currentTimelineItem?: TimelineItem,
  totalDuration: number,
  timelineIndex: number,
  currentTime: number,
  currentRemainingTime: number,
  // actions
  play: () => void,
  pause: () => void,
  completeExercise: () => void,
  gotoTimelineIndex: (index: number) => void
}

/**
 * Enchainement des exercises d'un workout
 */
class WorkoutExercices extends Component<WorkoutExercicesProps> {
  private audioFinalCountDown: HTMLAudioElement;

  state = {
    touchStart: 0,
    touchEnd: 0,
    openAddFavouriteModal: false
  }

  constructor(props: WorkoutExercicesProps) {
    super(props);
    this.audioFinalCountDown = new Audio(soundCountDown)
  }

  componentDidUpdate(prevProps: Readonly<WorkoutExercicesProps>,
                     prevState: Readonly<WorkoutExercicesProps>,
                     snapshot?: any) {
    if (prevProps.currentRemainingTime !== this.props.currentRemainingTime ||
      prevProps.isPlaying !== this.props.isPlaying) {
      if (this.props.currentRemainingTime === 3) {
        this.playEndSound()
      }
    }
  }

  play = () => {
    this.props.play()
  }

  pause = () => {
    this.props.pause()
  }

  gotoPreview = () => {
    const {gotoTimelineIndex} = this.props
    gotoTimelineIndex(0)
  }


  gotoNextExercise = () => {
    const {timelineIndex, gotoTimelineIndex} = this.props
    gotoTimelineIndex(timelineIndex + 1)
  }

  gotoPreviousExercise = () => {
    const {timelineIndex, gotoTimelineIndex} = this.props
    gotoTimelineIndex(timelineIndex - 1)
  }

  async playEndSound() {
    this.audioFinalCountDown.volume = 0.2
    try {
      await this.audioFinalCountDown.play()
    } catch (e) {
      console.error(e)
    }
  }

  completeExercise = () => {
    this.props.completeExercise()
  }

  handleTouchStart = (e: any) => {
    this.setState({
      touchStart: e.targetTouches[0].clientX,
      touchEnd: e.targetTouches[0].clientX
    });
  }

  handleTouchMove = (e: any) => {
    this.setState({
      touchEnd: e.targetTouches[0].clientX
    });
  }

  handleTouchEnd = () => {
    const {touchStart, touchEnd} = this.state
    if (touchStart - touchEnd > 150) {
      if (this.hasNext()) {
        this.gotoNextExercise()
      }
    }
    if (touchStart - touchEnd < -150) {
      if (this.hasPrevious()) {
        this.gotoPreviousExercise()
      }
    }
  }

  handleOpenFavouriteModal = () => {
    this.setState({
      openAddFavouriteModal: true
    })
  }

  handleCloseFavouriteModal = () => {
    this.setState({
      openAddFavouriteModal: false
    })
  }

  hasNext = (): boolean => {
    const {timeline, timelineIndex} = this.props
    return !!(timeline && (timelineIndex + 1 < timeline.length))
  }

  hasPrevious = (): boolean => {
    const {timelineIndex} = this.props
    return !!(timelineIndex > 0)
  }

  render() {
    const {
      series,
      currentTime,
      currentTimelineItem,
      currentRemainingTime,
      isPlaying,
      currentWorkout
    } = this.props

    const formattedTime = formatTime(currentRemainingTime)
    const formattedPlayerTime = formatTime(currentTime)
    const isRestingBetweenToFrac = currentTimelineItem?.type === 'FRACT_REST'
    const isRestingBetweenSeries = currentTimelineItem?.type === 'SERIES_REST'
    const isWarmup = currentTimelineItem?.type === 'WARMUP'

    const isTimeAttack = !!currentTimelineItem?.repetitions
    const hasNext = this.hasNext()
    const hasPrevious = this.hasPrevious()

    return (
      <div className={`workout-frame 
        ${isWarmup && 'warmup-time'} 
        ${isRestingBetweenToFrac && 'frac-resting-time'} 
        ${isRestingBetweenSeries && 'series-resting-time'}`}
       onTouchStart={this.handleTouchStart}
       onTouchMove={this.handleTouchMove}
       onTouchEnd={() => this.handleTouchEnd()}>
        {
          isWarmup
            ? <Link to={`/workout/edit?workout=${currentWorkout}`} className="retour-home-container">
              <i className="fa fa-arrow-left" aria-hidden="true"></i>
              <span className="sr-only">Retour début</span>
            </Link>
          : <button onClick={this.gotoPreview} className="retour-home-container">
              <i className="fa fa-arrow-left" aria-hidden="true"></i>
              <span className="sr-only">Retour début</span>
            </button>
        }
        <Timeline showNextExercise={!isWarmup && currentRemainingTime <= 10} />

        <Transition
          items={isWarmup}
          from={{ opacity: 0 }}
          enter={{ opacity: 1 }}
          leave={{ opacity: 0 }}>
          {show => show && (props =>
            <div style={props}>
              <WorkoutPreview series={series} />
            </div>)}
        </Transition>
        {
          isTimeAttack
            ? <div className="time-attack-container">
              <div className="repetition">{currentTimelineItem?.repetitions} fois</div>
              <div className="timer">
                <img src={logoImg} alt='' className="timer-logo-small" />
                {formattedPlayerTime}
              </div>
              <div className="time-attack-finish">
                <button className="btn" onClick={this.completeExercise}>
                  Taper ici une fois terminé
                </button>
              </div>
            </div>
            : <div className={`counter-container ${(isPlaying && currentRemainingTime <= 3) && 'count-down-animation'}`}>
              {formattedTime}
            </div>
        }
        {isWarmup && <button onClick={this.handleOpenFavouriteModal} className="ajout-favoris-container">
          <i className="fa fa-save" aria-hidden="true"/>Enregistrer la séance
        </button>}
        <AddFavouriteModal
          handleClose={this.handleCloseFavouriteModal}
          open={this.state.openAddFavouriteModal}
          currentWorkout={currentWorkout}
        />
        <WorkoutPlayer
          hasNext={hasNext}
          hasPrevious={hasPrevious}
          play={this.play}
          pause={this.pause}
          isPlaying={isPlaying}
          gotoNextExercise={this.gotoNextExercise}
          gotoPreviousExercise={this.gotoPreviousExercise}
        />
        {!isWarmup &&
          <div className="series-number">
            Série {(currentTimelineItem?.currentSeriesIndex ?? 0) + 1} <br/>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  isPlaying: isPlaying(state),
  isWorkoutEnd: isWorkoutEnd(state),
  currentTime: getPlayerTime(state),
  series: getSeries(state),
  timeline: getTimeline(state),
  timelineIndex: getTimelineIndex(state),
  currentWorkout: getCurrentWorkout(state),
  totalDuration: getTimelineDuration(state),
  currentTimelineItem: getCurrentTimelineItem(state),
  currentRemainingTime: getCurrentRemainingTime(state)
})

export default connect(
  mapStateToProps,
  {
    play: play,
    pause: pause,
    completeExercise: completeExercise,
    gotoTimelineIndex: gotoTimelineIndex
  }
)(WorkoutExercices)

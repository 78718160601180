import React, {Component} from 'react'
import {connect} from 'react-redux'
import {gotoTimelineIndex, pause, setSeries} from '../../../../store/workout/workoutActions'
import {parseWorkout, ParsingError} from '../../../../core/workout/parser/workoutParser'
import {ExerciseScores, getTimelineScores, isWorkoutEnd} from '../../../../store/workout/workoutSelector'
import {Series} from "../../../../core/workout/types/workout.d."
import {WorkoutScoresScreen} from "./WorkoutScoresScreen";
import WorkoutExercices from "./WorkoutExercises";

type WorkoutProps = {
  workoutExpression: string,
  index: number,
  // selector
  isWorkoutEnd: boolean,
  timelineScores: ExerciseScores[],
  // actions
  setSeries: (series: Series[], workoutExpression: string | null) => void,
  pause: () => void
  gotoTimelineIndex: (index: number) => void
}

class Workout extends Component<WorkoutProps> {
  UNSAFE_componentWillMount() {
    const {workoutExpression, index, gotoTimelineIndex, setSeries} = this.props
    try {
      const series = parseWorkout(workoutExpression)
      setSeries(series, workoutExpression)
      gotoTimelineIndex(index)
    } catch (e) {
      if (e instanceof ParsingError) {
        this.setState({
          error: `Token attendu ${e.tokensExpected.join(' | ')} à la position ${e.position}`
        })
      } else {
        console.error(e)
      }
    }
  }

  componentWillUnmount() {
    const {setSeries, pause} = this.props
    setSeries([], null)
    pause()
  }

  restart = () => {
    this.props.gotoTimelineIndex(0)
  }

  render() {
    const {
      workoutExpression,
      isWorkoutEnd,
      timelineScores
    } = this.props
    const error = (this?.state as any)?.error
    if (error) {
      return <div>
        {error}
      </div>
    }

    if (isWorkoutEnd) {
      return <WorkoutScoresScreen
        workoutExpression={workoutExpression}
        restart={this.restart} scores={timelineScores}
      />
    }
    return <WorkoutExercices />
  }
}

const mapStateToProps = (state: any) => ({
  isWorkoutEnd: isWorkoutEnd(state),
  timelineScores: getTimelineScores(state)
})

export default connect(
  mapStateToProps,
  {
    setSeries: setSeries,
    pause: pause,
    gotoTimelineIndex: gotoTimelineIndex
  }
)(Workout)

import {Series} from "../workout/types/workout.d.";
import {TimelineItem} from "./types/timeline";

export const convertSeriesToTimeline = (series: Series[]): TimelineItem[] => {
  let timeline: TimelineItem[] = []
  let time = 0
  timeline.push({
    startTime: 0,
    currentSeriesIndex: 0,
    currentExerciseIndex: null,
    duration: 5,
    type: 'WARMUP'
  })
  time += 5
  if (series) {
    for (let i = 0; i < series.length; i++) {
      const s = series[i]
      if (s.exercises) {
        for (let j = 0; j < s.exercises.length; j++) {
          const e =  s.exercises[j]
          timeline.push({
            startTime: time,
            currentSeriesIndex: i,
            currentExerciseIndex: j,
            duration: e.effort,
            repetitions: e.repetitions,
            type: 'EFFORT',
            effortType: e.type
          })
          if (e.effort) {
            time += e.effort
          }
          if (e.rest) {
            timeline.push({
              startTime: time,
              currentSeriesIndex: i,
              currentExerciseIndex: null,
              duration: e.rest,
              type: 'FRACT_REST'
            })
            time += e.rest
          }
        }
      }
      if (i !== series.length - 1) {
        if (s.rest) {
          timeline.push({
            startTime: time,
            currentSeriesIndex: i,
            currentExerciseIndex: null,
            duration: s.rest,
            type: 'SERIES_REST'
          })
          time += s.rest
        }
      }
    }
  }
  return timeline
}


export const computeTimelineDuration = (timeline: TimelineItem[]) => {
  let duration = 0
  timeline && timeline.forEach(t => {
    if (t.duration) {
      duration += t.duration
    }
  })
  return duration
}

import {createSelector} from 'reselect'
import {exercisesTypes, ExercisesTypesInfo, Series} from "../../core/workout/types/workout.d.";
import {TimelineItem} from "../../core/timeline/types/timeline";
import {computeTimelineDuration} from "../../core/timeline/timeline-utils";
import {TimelineItemScore} from "./workoutReducer";

export const isPlaying = (state: any): boolean => (
	state.workout?.player?.playing
)

export const getPlayerTime = (state: any): number => (
  state.workout?.player?.timer ?? 0
)

export const getTimeline = (state: any): TimelineItem[] => (
  state.workout?.timeline?.items
)

export const getSeries = (state: any): Series[] => (
  state.workout?.series?.items
)

export const getCurrentWorkout = (state: any): string | null => (
  state.workout?.currentWorkout
)


export const getTimelineIndex = (state: any): number => (
  state.workout?.timeline?.index ?? 0
)

export const getTimeAttackScores = (state: any): TimelineItemScore[] => (
  state.workout?.timeAttackScores
)

export type TimelineItemInfoDisplayInfo = {
  label: string;
  img?: any;
  imgShadow?: any,
  modeTimeAttack?: boolean;
  repetitions?: number;
  id: number;
}

export const getTimelineItemDisplayInfo = createSelector(
  getTimeline,
  (timelineItems: TimelineItem[]): TimelineItemInfoDisplayInfo[] => {
    let i = 0;
    return timelineItems && timelineItems.map(item => {
      switch (item.type) {
        case "EFFORT":
          return item.effortType
            ? {
              ...exercisesTypes[item.effortType],
              repetitions: item.repetitions,
              modeTimeAttack: !!item.repetitions,
              id: i++
            }
            : {label: '', id: i++}
        case "FRACT_REST":
          return {label: 'Récupération...', id: i++}
        case "SERIES_REST":
          return {label: 'Récupération avant la prochaine série...', id: i++}
        case "WARMUP":
          return {label: '', id: i++}
        default:
          return null
      }
    })
  }
)

export const getFirstSeries = createSelector(
  getSeries,
  (series: Series[]): Series | null => {
    if (!series || !series.length) {
      return null
    }
    return series[0]
  }
)

export const getPartialTimelineItemDisplayInfo = createSelector(
  getTimelineItemDisplayInfo,
  getTimelineIndex,
  (timelineItems: TimelineItemInfoDisplayInfo[], timelineIndex: number): TimelineItemInfoDisplayInfo[] => {
    if (!timelineItems) {
      return []
    }
    const borneMin = timelineIndex - 2 < 0 ? 0 : timelineIndex - 2
    const borneMax = timelineIndex + 2 > timelineItems.length ? timelineItems.length : timelineIndex + 2
    return timelineItems.slice(borneMin, borneMax)
  }
)

export const getTimelineDuration = createSelector(
  getTimeline,
  computeTimelineDuration
)

export const isWorkoutEnd = createSelector(
  getTimeline,
  getTimelineIndex,
  (timeline: TimelineItem[], index: number) => {
    return timeline && index >= timeline.length
  }
)

export const getCurrentTimelineItem = createSelector(
  getTimeline,
  getTimelineIndex,
  (timeline: TimelineItem[], index: number): (TimelineItem | undefined) => {
    if (index !== -1 && timeline && index < timeline.length) {
      return timeline[index]
    }
    return undefined
  }
)

export const getCurrentRemainingTime = createSelector(
  getPlayerTime,
  getCurrentTimelineItem,
  (time: number, currentItem?: TimelineItem): number => {
    if (!currentItem || !currentItem.duration) {
      return 0
    }
    return currentItem.duration - time
  }
)

export type ExerciseScores = {
  exerciseId: number,
  exercise: ExercisesTypesInfo,
  seriesScores: SeriesScores[]
}

export type SeriesScores = {
  seriesId: number,
  score: number
}

export const getTimelineScores = createSelector(
  getTimeAttackScores,
  getTimeline,
  (scores: TimelineItemScore[], timeline: TimelineItem[]): ExerciseScores[] => {
    if (timeline && timeline.length && scores) {
      let result: ExerciseScores[] = []
      scores.forEach(timelineScore => {
        const timelineItem = timelineScore.timelineItem
        if (timelineItem.currentExerciseIndex !== null) {
          let currentExerciceScores = result.find(e => e.exerciseId === timelineItem.currentExerciseIndex)
          if (!currentExerciceScores) {
            currentExerciceScores = {
              exerciseId: timelineItem.currentExerciseIndex,
              exercise: timelineItem.effortType && exercisesTypes[timelineItem.effortType],
              seriesScores: []
            }
            result.push(currentExerciceScores)
          }
          currentExerciceScores.seriesScores.push({
            seriesId: timelineItem.currentSeriesIndex,
            score: timelineScore.score
          })
        }
      })
      return result
    }
    return []
  }
)

import axios from "axios";
import {config} from '../conf/config'

export const redirectToStrava = (returnUrl: string): void => {
  window.location.href =
    'https://www.strava.com/oauth/authorize?' +
    `client_id=${config.strava.clientId}` +
    '&response_type=code' +
    '&redirect_uri=' + returnUrl +
    '&approval_prompt=auto' +
    '&scope=activity:write' +
    '&approval_prompt=force'
}

export const getToken = async (authorizationCode: string): Promise<string | undefined | null> => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/v3/oauth/token?client_id=${config.strava.clientId}&grant_type=authorization_code&code=${authorizationCode}`
    )
    return response.data?.access_token
  } catch (e) {
    console.error(e)
    return null
  }
}

interface Activity {
  name?: string,
  type?: 'Workout',
  start_date_local: string,
  elapsed_time: number,
  description?: string,
  trainer: number,
  commute: number
}

export const postActivity = async (activity: Activity, token: string): Promise<any> => {
  const params = new URLSearchParams();
  for (let key in activity) {
    params.append(key, activity[key]);
  }

  try {
    await axios.post(
      `https://www.strava.com/api/v3/activities`,
      params,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  } catch (e) {
    console.error(e)
  }
}

import {AnyAction} from 'redux'
import {Favourite} from "./favourites";
import {types} from "./favouritesActions";

const initialFavouritesState: Favourite[] = []

export const favourites = (state = initialFavouritesState, action: AnyAction) => {
  switch (action.type) {
    case types.SET_FAVOURITES: {
      return action.payload
    }
    default:
      return state
  }
}

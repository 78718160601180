import React, {Component} from 'react'
import {Router} from 'react-router-dom'
import {Provider} from 'react-redux'
import configureStore, {history} from './store/configureStore'
import {Routes} from './Routes'
import {SnackbarProvider} from "notistack"
import ServiceWorkerRegister from "./ui/ServiceWorkerRegister"
import {initFavourites} from "./store/favourites/favouritesActions";
import {loadFavourites} from "./storage/LocalStorage";

const store = configureStore({})

export class App extends Component<any> {
  reloadLocalStorage = () => {
    const favourites = loadFavourites()
    initFavourites(favourites)(store.dispatch)
  }

  componentDidMount() {
    this.reloadLocalStorage()
  }

  render() {
    return (
      <Provider store={store}>
        <SnackbarProvider>
          <Router history={history}>
            <Routes/>
          </Router>
          <ServiceWorkerRegister/>
        </SnackbarProvider>
      </Provider>
    )
  }
}

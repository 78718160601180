import React, {Component} from 'react'
import './WorkoutPreview.scss'
import {exercisesTypes, Series} from "../../../../core/workout/types/workout.d.";

type WorkoutPreviewProps = {
  series: Series[],
  color?: string
}

export default class WorkoutPreview extends Component<WorkoutPreviewProps> {
  render() {
    const { series, color } = this.props
    const firstSeries = series && series.length ? series[0] : null
    return (<div className="workout-preview">
      <div className="cycle-container">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
          <g fill={color || 'white'}>
            <path d="m390.16309,111.0562c-28.51782,-28.51867 -64.50733,-47.34843 -104.07357,-54.45387c-38.63304,-6.93818 -77.99256,-2.30558 -113.81739,13.39174l19.25262,43.93724c56.46095,-24.73951 121.11925,-12.55631 164.71768,31.04469c57.91824,57.91824 57.91824,152.15879 0,210.07703c-57.91824,57.9191 -152.16051,57.9191 -210.0796,0c-29.31636,-29.31723 -44.51364,-68.33709 -43.56755,-108.22412l57.56657,16.27286l-19.46448,-76.83635l-19.46533,-76.83806l-56.8092,55.27729l-56.81091,55.27558l47.75929,13.49982c-1.67258,19.89249 -0.32337,40.02685 4.09223,59.71004c8.13557,36.26827 26.38636,69.38867 52.78044,95.7836c37.11742,37.11657 86.46779,57.55799 138.95832,57.55799c0.00343,0 -0.00172,0 0.00172,0c52.48709,0 101.84348,-20.444 138.96089,-57.55799c37.11657,-37.11742 57.55714,-86.46866 57.55714,-138.96089c-0.00085,-52.48881 -20.44229,-101.84004 -57.55886,-138.95661l-0.00002,0.00002l0.00001,-0.00001z"/>
          </g>
          <g fill={color || 'white'}>
            <text x={215} y={265} fontSize="2rem" fontWeight={500}>
              {series.length} fois
            </text>
            <text x={180} y={290} fontSize="1.2rem" fontWeight={500}>
              avec {firstSeries?.rest}" de récup
            </text>
          </g>
          <g>
          {firstSeries && firstSeries.exercises.map((item, index) => {
            const angle = index * 2 * Math.PI / firstSeries.exercises.length - Math.PI / 2
            const textPositionX = Math.cos(angle) * 180 + 210
            const textPositionY = Math.sin(angle) * 180 + 220
            const exerciseType = item.type && exercisesTypes[item.type]
            const fontSize = '0.6rem'
            const widthText = (exerciseType?.label?.length ?? 0) * 2
            const positionLabelX = textPositionX - widthText < 0 ? 0 : textPositionX - widthText / 2
            return (
              <g fill="#303538" key={index}>
                <text x={positionLabelX} y={textPositionY} fontSize="0.7rem" fontWeight={500}>
                  {exerciseType?.label ?? 'Travail'}
                </text>
                <text x={positionLabelX} y={textPositionY + 15} fontSize="0.7rem" fontWeight={500}>
                  pendant {item.effort} secondes
                </text>
                {item.rest &&
                  <text x={positionLabelX} y={textPositionY + 28} fontSize={fontSize} fontWeight={500}>
                    (puis {item.rest} secondes de récup)
                  </text>
                }
                {
                  exerciseType && exerciseType?.imgShadow &&
                  <image href={exerciseType?.imgShadow} width={100} x={positionLabelX} y={textPositionY + (!!item.rest ? 30 : 28) }/>
                }
              </g>
            )
          })
          }
          </g>
        </svg>
      </div>
    </div>)
  }
}

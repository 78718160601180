import {applyMiddleware, compose, createStore} from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers'
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

let enhancers: any
if (process.env.NODE_ENV === 'production') {
  enhancers = [applyMiddleware(thunk)]
} else {
  // DEV
  // @ts-ignore
  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    // utilisation de redux dev tools uniquement
    // @ts-ignore
    enhancers = [applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__()]
  } else {
    enhancers = [applyMiddleware(thunk)]
  }
}

export default function configureStore(initialState: any) {
  const store = createStore(
    rootReducer,
    initialState,
    compose(...enhancers)
  )
  // @ts-ignore
  if (module.hot) {
    // @ts-ignore
    module.hot.accept('./reducers', () => {
      store.replaceReducer(require('./reducers')) // eslint-disable-line global-require
    })
  }
  return store
}

import gainageImg from '../../../resources/img/exercises/gainage.png'
import gainageLatheralDroitImg from '../../../resources/img/exercises/gainage_latheral_droit.png'
import gainageLatheralGaucheImg from '../../../resources/img/exercises/gainage_latheral_gauche.png'
import squatImg from '../../../resources/img/exercises/squat.png'
import gainageEpauleImg from '../../../resources/img/exercises/gainage_epaule.png'
import pompeImg from '../../../resources/img/exercises/pompes.png'
import jacksImg from '../../../resources/img/exercises/jacks.png'
import burpeesImg from '../../../resources/img/exercises/burpees.png'
import fentesImg from '../../../resources/img/exercises/fentes.png'
import supermanImg from '../../../resources/img/exercises/superman.png'
import abdoLatheralImg from '../../../resources/img/exercises/abdo_latheral.png'
import dipsImg from '../../../resources/img/exercises/dips.png'
import canoeImg from '../../../resources/img/exercises/canoe.png'
import gainageDorsalImg from '../../../resources/img/exercises/gainage-dorsal.png'
import gainageDorsalDynImg from '../../../resources/img/exercises/gainage-dorsal-dynamique.png'
import crunchsInversesImg from '../../../resources/img/exercises/crunchs_inverses.png'
import crunchsInversesBlackImg from '../../../resources/img/exercises/black/crunchs_inverses.png'
import montainImg from '../../../resources/img/exercises/abdo_montee_genou.png'
import sautImg from '../../../resources/img/exercises/saut.png'
import gainageBlackImg from '../../../resources/img/exercises/black/gainage.png'
import gainageLatheralDroitBlackImg from '../../../resources/img/exercises/black/gainage_latheral_droit.png'
import gainageLatheralGaucheBlackImg from '../../../resources/img/exercises/black/gainage_latheral_gauche.png'
import squatBlackImg from '../../../resources/img/exercises/black/squat.png'
import gainageEpauleBlackImg from '../../../resources/img/exercises/black/gainage_epaule.png'
import pompeBlackImg from '../../../resources/img/exercises/black/pompes.png'
import jacksBlackImg from '../../../resources/img/exercises/black/jacks.png'
import burpeesBlackImg from '../../../resources/img/exercises/black/burpees.png'
import fentesBlackImg from '../../../resources/img/exercises/black/fentes.png'
import supermanBlackImg from '../../../resources/img/exercises/black/superman.png'
import abdoLatheralBlackImg from '../../../resources/img/exercises/black/abdo_latheral.png'
import dipsBlackImg from '../../../resources/img/exercises/black/dips.png'
import canoeBlackImg from '../../../resources/img/exercises/black/canoe.png'
import gainageDorsalBlackImg from '../../../resources/img/exercises/black/gainage-dorsal.png'
import gainageDorsalDynBlackImg from '../../../resources/img/exercises/black/gainage-dorsal-dynamique.png'
import montainBlackImg from '../../../resources/img/exercises/black/abdo_montee_genou.png'
import sautBlackImg from '../../../resources/img/exercises/black/saut.png'

export interface Series {
  rest: number,
  exercises: Exercise[]
}

export interface Exercise {
  rest: number,
  effort?: number | null,
  repetitions?: number | null,
  type?: string
}

export interface ExercisesTypesInfo {
  label: string,
  img?: any,
  imgShadow?: any
}
export const exercisesMembresSuperieursTypes = {
  sp: {label: 'Position pompe', img: pompeImg, imgShadow: pompeBlackImg},
  spm: {label: 'Pompes', img: pompeImg, imgShadow: pompeBlackImg},
  sd: {label: 'Position dips', img: dipsImg, imgShadow: dipsBlackImg},
  sdm: {label: 'Dips', img: dipsImg, imgShadow: dipsBlackImg},
}
export const exercisesAbdosTypes = {
  aglg: {label: 'Gainage latérale gauche', img: gainageLatheralGaucheImg, imgShadow: gainageLatheralGaucheBlackImg},
  agld: {label: 'Gainage latéral droit', img: gainageLatheralDroitImg, imgShadow: gainageLatheralDroitBlackImg},
  aglgmo: {label: 'Gainage latérale gauche dynamique', img: gainageLatheralGaucheImg, imgShadow: gainageLatheralGaucheBlackImg},
  agldmo: {label: 'Gainage latérale droit dynamique', img: gainageLatheralDroitImg, imgShadow: gainageLatheralDroitBlackImg},
  aglgmj: {label: 'Gainage latérale gauche, jambe levée', img: gainageLatheralGaucheImg, imgShadow: gainageLatheralGaucheBlackImg},
  agldmj: {label: 'Gainage latérale droit, jambe levée', img: gainageLatheralDroitImg, imgShadow: gainageLatheralDroitBlackImg},
  ag: {label: 'Gainage', img: gainageImg, imgShadow: gainageBlackImg},
  agm: {label: 'Gainage dynamique', img: gainageImg, imgShadow: gainageBlackImg},
  agt: {label: 'Gainage avec torsions', img: gainageLatheralDroitImg, imgShadow: gainageLatheralDroitBlackImg},
  agep: {label: 'Gainage en touchant les épaules', img: gainageEpauleImg, imgShadow: gainageEpauleBlackImg},
  acm: {label: 'Crunchs', img: crunchsInversesImg, imgShadow: crunchsInversesBlackImg },
  actc: {label: 'Touchée de chevilles', img: abdoLatheralImg, imgShadow: abdoLatheralBlackImg},
  amon: {label: 'Montain Climber', img: montainImg, imgShadow: montainBlackImg},
  acan: {label: 'Canoë', img: canoeImg, imgShadow: canoeBlackImg},
}

export const exercisesJambesTypes = {
  jfg: {label: 'Position fente jambe gauche', img: fentesImg , imgShadow: fentesBlackImg },
  jfd: {label: 'Position fente jambe droite', img: fentesImg , imgShadow: fentesBlackImg },
  jfm: {label: 'Fentes', img: fentesImg , imgShadow: fentesBlackImg },
  jfmg: {label: 'Fentes sur jambe gauche', img: fentesImg , imgShadow: fentesBlackImg },
  jfmd: {label: 'Fentes sur jambe droite', img: fentesImg , imgShadow: fentesBlackImg },
  jflm: {label: 'Fentes lathérales', img: fentesImg , imgShadow: fentesBlackImg },
  js: {label: 'La chaise', img: squatImg, imgShadow: squatBlackImg},
  jsm: {label: 'Squats', img: squatImg, imgShadow: squatBlackImg},
  jsp: {label: 'Pistol squat'},
  jss: {label: 'Squats sautés', img: squatImg, imgShadow: squatBlackImg},
}

export const exercisesDosTypes = {
  ds: {label: 'Position superman', img: supermanImg, imgShadow: supermanBlackImg},
  dsm: {label: 'Superman', img: supermanImg, imgShadow: supermanBlackImg},
  dg: {label: 'Le pont', img: gainageDorsalImg, imgShadow: gainageDorsalBlackImg},
  dgjd: {label: 'Pont avec levé jambe droite', img: gainageDorsalDynImg, imgShadow: gainageDorsalDynBlackImg},
  dgjg: {label: 'Pont avec levé jambe gauche', img: gainageDorsalDynImg, imgShadow: gainageDorsalDynBlackImg},
  dgm: {label: 'Pont avec levés de jambes', img: gainageDorsalDynImg, imgShadow: gainageDorsalDynBlackImg},
  dma: {label: 'Mouvement d\'Arlaud', img: supermanImg, imgShadow: supermanBlackImg},
}

export const exercisesCardioTypes = {
  cb: {label: 'Burpees', img: burpeesImg, imgShadow: burpeesBlackImg},
  cjj: {label: 'Jumping-jack', img: jacksImg, imgShadow: jacksBlackImg},
  cs: {label: 'Saut sur place', img: sautImg, imgShadow: sautBlackImg},
  // cpmjgd (ding-dong)
}

export const exercisesTypes = {
  ...exercisesMembresSuperieursTypes,
  ...exercisesAbdosTypes,
  ...exercisesJambesTypes,
  ...exercisesDosTypes,
  ...exercisesCardioTypes,
}

import React, {FunctionComponent} from "react";
import './WorkoutPlayer.scss'

type PlayerProps = {
  hasPrevious: boolean,
  hasNext: boolean,
  isPlaying: boolean,
  play: () => void,
  pause: () => void,
  gotoPreviousExercise: () => void,
  gotoNextExercise: () => void
}
export const WorkoutPlayer: FunctionComponent<PlayerProps> = (
  {
    hasPrevious,
    hasNext,
    isPlaying,
    pause,
    play,
    gotoPreviousExercise,
    gotoNextExercise
  }
  ) => (
  <div className="player">
    <div className="player-controls">
      <div>
        { hasPrevious && <i className="fa fa-chevron-left small" onClick={gotoPreviousExercise}/> }
      </div>
      {
        isPlaying
          ? <div><i className="far fa-pause-circle" onClick={pause}/></div>
          : <div><i className="far fa-play-circle" onClick={play}/></div>
      }
      <div>
        { hasNext && <i className="fa fa-chevron-right small" onClick={gotoNextExercise}/> }
      </div>
    </div>
  </div>
)

import { combineReducers } from 'redux'
import { workout } from './workout/workoutReducer'
import { favourites } from './favourites/favouritesReducer'
import { routerReducer as routing } from 'react-router-redux'
import { reducer as formReducer } from 'redux-form'

const rootReducer = combineReducers({
  workout,
  favourites,
  routing,
  form: formReducer
})

export default rootReducer

import React, {Component} from 'react'
import './FavouritesPage.scss'
import NavigationBottomBar from "../../layout/bottomBar/NavigationBottomBar"
import {Header} from "../../layout/header/Header"
import FavouritesList from "./components/FavouritesList";

export default class FavouritesPage extends Component<any> {
  render() {
    return (
      <div className="favourites">
        <NavigationBottomBar />
        <Header thin />
        <main className="main">
          <section className="section-favourites">
            <h2>Mes séances</h2>
            <FavouritesList />
          </section>
        </main>
      </div>
    )
  }
}

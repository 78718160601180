import React, {Component} from 'react'
import './CreationSeanceForm.scss'
import {Field, FieldArray, InjectedFormProps, reduxForm} from 'redux-form'
import {SelectField} from "../../../components/form/SelectField";
import {ReduxTextField} from "../../../components/form/ReduxTextField";
import {ListSubheader, MenuItem} from "@material-ui/core";
import {
  exercisesAbdosTypes,
  exercisesCardioTypes,
  exercisesDosTypes,
  exercisesJambesTypes,
  exercisesMembresSuperieursTypes,
  exercisesTypes
} from "../../../../core/workout/types/workout.d.";

function isNumeric(n: any) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

const validateExercise: any = (values: any) => {
  let errors: any = {}
  if (!values.effort && values.effort !== 0) {
    errors.effort = 'Champ obligatoire'
  } else if(!isNumeric(values.effort)) {
    errors.effort = 'Format invalide'
  } else if (+values.effort < 0) {
    errors.effort = 'Format invalide'
  }

  if (!values.rest && values.rest !== 0) {
    errors.rest = 'Champ obligatoire'
  } else if(!isNumeric(values.rest)) {
    errors.rest = 'Format invalide'
  } else if (+values.rest < 0) {
    errors.rest = 'Format invalide'
  }
  return errors
}

const validate: any = (values: any) => {
  let errors: any = {}
  if (!values.nbSeries && values.nbSeries !== 0) {
    errors.nbSeries = 'Champ obligatoire'
  } else if(!isNumeric(values.nbSeries)) {
    errors.nbSeries = 'Format invalide'
  } else if (+values.nbSeries <= 0 || +values.nbSeries > 100) {
    errors.nbSeries = 'Le nombre de séries doit être compris entre 1 et 100'
  }

  if (!values.restSeries && values.restSeries !== 0) {
    errors.restSeries = 'Champ obligatoire'
  } else if(!isNumeric(values.restSeries)) {
    errors.restSeries = 'Format invalide'
  } else if (+values.restSeries < 0) {
    errors.restSeries = 'Format invalide'
  }

  if (!values.exercises || !values.exercises.length) {
    errors._error = 'Ajoutez au moins 1 exercice'
  } else {
    errors.exercises = []
    values.exercises.forEach((e: any) => {
      const errorExercise = validateExercise(e)
      errors.exercises.push(errorExercise)
    })
  }
  return errors
}

export interface ExercisesFormProps {
  type: string
  effort: string
  rest: string
}

export interface SeriesFormProps {
  nbSeries: string
  restSeries: string
  exercises: ExercisesFormProps[]
}

interface CreationSeanceProps {
  addToFavourite: any
}

class CreationSeanceForm extends Component<CreationSeanceProps & InjectedFormProps<SeriesFormProps, CreationSeanceProps>> {

  render() {
    const {
      submitting,
      valid,
      handleSubmit,
      addToFavourite
    } = this.props
    return (
      <form onSubmit={handleSubmit} className="creation-seance-form">
        <div className="title-container">
          <h3>Séries</h3>
          <div className="action-button-top-container">
            <div className="action-button">
              <button type="submit"
                      className="square-btn"
                      disabled={submitting || !valid}>
                Lancer la séance
                <span className="right-icon"><i className="fa fa-arrow-right"/></span>
              </button>
            </div>
          </div>
        </div>
        <div className="series-fields">
          <div className="series-field">
            <Field name="nbSeries" label="Nombre de séries" placeholder="" type="number" maxLength={250} component={ReduxTextField} autoComplete="off"/>
          </div>
          <div className="series-field">
            <Field name="restSeries" label="Durée de récupération (en secondes)" placeholder="" type="number" maxLength={250} component={ReduxTextField} autoComplete="off"/>
          </div>
        </div>
        <div className="exercices-container">
          <h3>Exercices</h3>
          <FieldArray name="exercises" component={renderExercises}/>
        </div>
        <div className="action-button">
          <button type="submit" className="square-btn" disabled={submitting || !valid}>
            Lancer la séance
            <span className="right-icon"><i className="fa fa-arrow-right"/></span>
          </button>
          <button type="button"
                  className="square-btn add-to-favourite"
                  disabled={submitting || !valid}
                  onClick={handleSubmit(values => {
                    addToFavourite(values)
                  })}
          >
            <i className="fa fa-save" />Enregistrer la séance
          </button>
        </div>
      </form>
    )
  }
}

const renderExercises = ({fields}: any) => {
  return (
    <ul className="exercises-list">
      {
        (!fields || !fields.length) &&
          <li>
            Ajoutez un exercice en cliquant sur le bouton suivant :
          </li>
      }
      {fields && fields.map((exercise: ExercisesFormProps, index: number) => (
        <li className="exercises-item" key={index}>
          <fieldset>
            <legend>Exercice {index + 1}</legend>
            <div className="order-exercise-container">
              <button
                title="Remonter"
                disabled={ index === 0 }
                onClick={(e) => {
                  fields.move(index, index - 1)
                  e.preventDefault()
                }}>
                <i className="fa fa-arrow-up" />
              </button>
              <button
                title="Redescendre"
                disabled={index >= fields.length - 1}
                onClick={(e) => {
                  fields.move(index, index + 1)
                  e.preventDefault()
                }}>
                <i className="fa fa-arrow-down" />
              </button>
            </div>
            <div className="delete-exercise-container">
              <button
                title="Supprimer"
                onClick={(e) => {
                fields.remove(index)
                e.preventDefault()
              }}>
                <i className="fa fa-times" />
              </button>
            </div>
            <div className="exercise-fields">
              <div className="exercise-field">
                <Field name={`${exercise}.effort`} type="number" label="Durée de l'effort (en secondes)" placeholder="" maxLength={100} component={ReduxTextField}/>
              </div>
              <div className="exercise-field">
                <Field name={`${exercise}.rest`} type="number" label="Durée de récupération (en secondes)" placeholder="" maxLength={100} component={ReduxTextField}/>
              </div>
              <div className="exercise-field">
                <Field name={`${exercise}.type`} label="Type" component={SelectField}>
                  <MenuItem value="-1" selected>&nbsp;</MenuItem>
                  <ListSubheader>Membres supérieurs</ListSubheader>
                  { Object.keys(exercisesMembresSuperieursTypes).map(renderExercisesOptions) }
                  <ListSubheader>Ceinture abdominale</ListSubheader>
                  { Object.keys(exercisesAbdosTypes).map(renderExercisesOptions) }
                  <ListSubheader>Jambes</ListSubheader>
                  { Object.keys(exercisesJambesTypes).map(renderExercisesOptions) }
                  <ListSubheader>Dos</ListSubheader>
                  { Object.keys(exercisesDosTypes).map(renderExercisesOptions) }
                  <ListSubheader>Cardio</ListSubheader>
                  { Object.keys(exercisesCardioTypes).map(renderExercisesOptions) }
                </Field>
              </div>
            </div>
          </fieldset>
        </li>
      ))}
      <li className="add-exercise-container">
        <button
          className="square-btn"
          onClick={(e) => {
          fields.push({ rest: "30", effort: "30", type: "-1" })
          e.preventDefault()
        }}>
          <i className="fa fa-plus"/> Ajouter un exercice
        </button>
      </li>
    </ul>
  )
}

const renderExercisesOptions = (key: string) => {
  const exercise = exercisesTypes[key]
  return (<MenuItem value={key} key={key}>{exercise.label}</MenuItem>)
}

export default reduxForm<SeriesFormProps, CreationSeanceProps>({
  form: 'CreationSeanceForm',
  validate
})(CreationSeanceForm)

import React from 'react'
import ReactDOM from 'react-dom'
import {App} from './App'
import {reportWebVitals} from './reportWebVitals'
import './resources/styles/styles.scss'
import '@fortawesome/fontawesome-free/css/all.min.css'

const rootElement = document.getElementById('root')

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   rootElement
// )

ReactDOM.render(<App />, rootElement)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register({
//   onUpdate,
//   onSuccess
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import './SimpleModal.scss'

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      border: 'none',
      outline: 'none',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
)

interface SimpleModalProps {
  children: any,
  ariaLabelledby?: string,
  ariaDescribedby?: string,
  open: boolean,
  handleClose: () => void,
}


export default function SimpleModal(props: SimpleModalProps) {
  const { children, handleClose, open, ariaDescribedby, ariaLabelledby } = props
  const classes = useStyles()
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle)

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby={ariaLabelledby}
        aria-describedby={ariaDescribedby}
      >
        <div style={modalStyle} className={`${classes.paper} modal`}>
          {
            handleClose &&
            <div className="delete-exercise-container">
              <button
                title="Supprimer"
                onClick={handleClose}>
                <i className="fa fa-times" />
              </button>
            </div>
          }
          {children}
        </div>
      </Modal>
    </div>
  )
}

import React from "react";
import {TextField} from "@material-ui/core";
// @ts-ignore
import { borderedTextFieldStylesHook } from '@mui-treasury/styles/textField/bordered';
import './ReduxTextField.scss'

export const ReduxTextField = ({
                           label,
                           input,
                           className,
                           meta: { touched, invalid, error },
                           ...custom
                         }: any) => {
  const inputBaseStyles = borderedTextFieldStylesHook.useInputBase()
  inputBaseStyles.adornedStart = undefined
  inputBaseStyles.adornedEnd = undefined
  const inputLabelStyles = borderedTextFieldStylesHook.useInputLabel()
  inputLabelStyles.adornedStart = undefined
  inputLabelStyles.adornedEnd = undefined
  return <TextField
    className={`custom-textfield ${className || ''}`}
    label={label}
    error={touched && invalid}
    helperText={touched && error}
    InputLabelProps={{shrink: true, classes: inputLabelStyles}}
    InputProps={{ classes: inputBaseStyles, disableUnderline: true }}
    {...input}
    {...custom}
  />
}

import React, {Component} from 'react'
import SimpleModal from "../modal/SimpleModal";
import EditFavouriteForm, {EditFavouriteFormProps} from "./EditFavouriteForm";
import {connect} from "react-redux";
import {addFavourite} from "../../../store/favourites/favouritesActions";
import {ProviderContext, withSnackbar} from "notistack";
import {Favourite} from "../../../store/favourites/favourites";
// @ts-ignore
import {v4 as uuidv4} from 'uuid'

interface AddFavouriteModalProps {
  open: boolean,
  handleClose: () => void,
  currentWorkout: string | null,
  // redux
  addFavourite: (favourite: Favourite) => void
}

class AddFavouriteModal extends Component<AddFavouriteModalProps & ProviderContext> {
  handleSubmit = (values: EditFavouriteFormProps) => {
    const {addFavourite, currentWorkout, handleClose} = this.props

    addFavourite({
      workoutExpression: currentWorkout ?? '',
      name: values.name,
      date: new Date(),
      guid: uuidv4()
    })
    this.notifyAddFavourite()
    handleClose()
  }

  notifyAddFavourite = () => {
    const { enqueueSnackbar } = this.props
    enqueueSnackbar("Séance sauvegardée", {
      persist: false,
      autoHideDuration: 3000,
      variant: "success",
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'left',
      }
    })
  }

  render() {
    const {open, handleClose} = this.props
    const now = new Date()
    const name = 'Séance du ' + now.toLocaleDateString()
    return <SimpleModal
        open={open}
        handleClose={handleClose}
        ariaLabelledby="ajout-mes-seances-title"
        ariaDescribedby="ajout-mes-seances-description"
      >
        <div>
          <h2 id="ajout-mes-seances-title">Enregistrer la séance</h2>
          <p id="ajout-mes-seances-description">
            Sauvegardez la séance en cours et retrouvez là dans votre liste "mes séances".
          </p>
          <EditFavouriteForm
            onSubmit={this.handleSubmit}
            onCancel={handleClose}
            buttonValiderContent={<><i className="fa fa-check" />Valider</>}
            initialValues={{
              name
            }}
          />
        </div>
      </SimpleModal>
  }
}

const mapStateToProps = () => ({
})

export default connect(
  mapStateToProps,
  {
    addFavourite: addFavourite
  }
)(withSnackbar(AddFavouriteModal))

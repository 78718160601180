import React from "react";
import {FormControl, InputLabel, Select} from "@material-ui/core";
// @ts-ignore
import { useBorderSelectStyles } from '@mui-treasury/styles/select/border';
import './SelectField.scss'

export const SelectField = ({
                       input,
                       label,
                       meta: {touched, error},
                       children,
                       ...custom
                     }: any) => {
  const borderSelectClasses = useBorderSelectStyles();
  const menuProps = {
    classes: {
      list: borderSelectClasses.list
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    getContentAnchorEl: null
  };

  return <FormControl error={touched && error} className="custom-select">
    <InputLabel
      className={borderSelectClasses.label}
      htmlFor={input.name}
    >{label}</InputLabel>
    <Select
      disableUnderline
      classes={{ root: borderSelectClasses.select }}
      id={input.name}
      MenuProps={menuProps}
      {...input}
      {...custom}
    >
      {children}
    </Select>
  </FormControl>
}

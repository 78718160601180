import {
  getCurrentRemainingTime, getCurrentTimelineItem,
  getPlayerTime,
  getTimeline,
  getTimelineIndex,
  isPlaying,
  isWorkoutEnd
} from './workoutSelector'
import {Series} from "../../core/workout/types/workout.d.";
import {convertSeriesToTimeline} from "../../core/timeline/timeline-utils";
import {TimelineItem} from "../../core/timeline/types/timeline";

const ACTION_NAMESPACE = '/WORKOUT'

export const types = {
	SET_PLAY: `${ACTION_NAMESPACE}/SET_PLAY`,
  SET_TIME: `${ACTION_NAMESPACE}/SET_TIME`,
  SET_TIMELINE: `${ACTION_NAMESPACE}/SET_TIMELINE`,
  SET_SERIES: `${ACTION_NAMESPACE}/SET_SERIES`,
  SET_CURRENT_WORKOUT_EXPRESSION: `${ACTION_NAMESPACE}/SET_CURRENT_WORKOUT_EXPRESSION`,
  GOTO_TIMELINE_INDEX: `${ACTION_NAMESPACE}/GOTO_TIMELINE_INDEX`,
  ADD_SCORE_TIME_ATTACK: `${ACTION_NAMESPACE}/ADD_SCORE_TIME_ATTACK`
}

let playerInterval: any = null


export const setTimeline = (timeline: TimelineItem[]) => (dispatch: any) => {
  dispatch({
    type: types.SET_TIMELINE,
    payload: timeline
  })
}

export const setSeries = (series: Series[], workoutExpression: string | null) => (dispatch: any) => {
  dispatch({
    type: types.SET_SERIES,
    payload: series
  })
  dispatch({
    type: types.SET_CURRENT_WORKOUT_EXPRESSION,
    payload: workoutExpression
  })
  const timeline = convertSeriesToTimeline(series)
  dispatch(setTimeline(timeline))
}

export const play = () => (dispatch: any, getState: any) => {
	if (!isPlaying(getState())) {
    dispatch({
      type: types.SET_PLAY,
      payload: true
    })
    playerInterval = setInterval(() => {
      dispatch(incrementTime(), getState)
    }, 1000)
  }
}

const incrementTime = () => (dispatch: any, getState: any) => {
  const currentTime = getPlayerTime(getState())
  const modeTimeAttack = !!getCurrentTimelineItem(getState())?.repetitions
  if (modeTimeAttack || getCurrentRemainingTime(getState()) > 0) {
    dispatch(setTime(currentTime + 1), getState)
  } else {
    dispatch(gotoToNextTimelineItem())
  }
}

export const setTime = (time: number) => (dispatch: any, getState: any) => {
  dispatch({
    type: types.SET_TIME,
    payload: time
  })
}

export const pause = () => (dispatch: any, getState: any) => {
  if (isPlaying(getState())) {
    dispatch({
      type: types.SET_PLAY,
      payload: false
    })
    playerInterval && clearInterval(playerInterval)
    playerInterval = null
  }
}

export const gotoToNextTimelineItem = () => (dispatch: any, getState: any) => {
  if (!isWorkoutEnd(getState())) {
    dispatch(gotoTimelineIndex(getTimelineIndex(getState()) + 1))
  }
}

export const gotoTimelineIndex = (index: number) => (dispatch: any, getState: any) => {
  const timeline = getTimeline(getState())
  if (timeline && index >= 0 && index < timeline.length) {
    dispatch(setTime(0))
    dispatch({
      type: types.GOTO_TIMELINE_INDEX,
      payload: index
    })
  } else {
    dispatch(pause())
    dispatch({
      type: types.GOTO_TIMELINE_INDEX,
      payload: timeline.length
    })
  }
}

export const completeExercise = () => (dispatch: any, getState: any) => {
  const timer = getPlayerTime(getState())
  const timelineItem = getCurrentTimelineItem(getState())
  dispatch(gotoToNextTimelineItem())
  dispatch({
    type: types.ADD_SCORE_TIME_ATTACK,
    payload: {
      score: timer,
      timelineItem
    }
  })
}

import React, {Component} from 'react'
import SimpleModal from "../../../components/modal/SimpleModal";
import {connect} from "react-redux";
import {renameFavourite} from "../../../../store/favourites/favouritesActions";
import {ProviderContext, withSnackbar} from "notistack";
import EditFavouriteForm from "../../../components/favourites/EditFavouriteForm";
import {Favourite} from "../../../../store/favourites/favourites";

interface AddFavouriteModalProps {
  open: boolean,
  handleClose: () => void,
  favourite?: Favourite | null,
  // redux
  renameFavourite: (favourite: Favourite, newName: string) => void
}

class RenameFavouriteModal extends Component<AddFavouriteModalProps & ProviderContext> {
  handleSubmit = (values: any) => {
    const {renameFavourite, favourite, handleClose} = this.props

    if (favourite) {
      renameFavourite(favourite, values.name)
    }
    handleClose()
  }

  render() {
    const {open, handleClose, favourite} = this.props
    const name = favourite?.name

    return <SimpleModal
        open={open}
        handleClose={handleClose}
        ariaLabelledby="rename-seance-title"
        ariaDescribedby="rename-seance-description"
      >
        <div>
          <h2 id="rename-seance-title">Renommer la séance</h2>
          <p id="rename-seance-description">
            Saisissez le nouveau nom que vous voulez donner à la séance.
          </p>
          <EditFavouriteForm
            onSubmit={this.handleSubmit}
            onCancel={handleClose}
            buttonValiderContent={<><i className="fa fa-check" />Valider</>}
            initialValues={{
              name
            }}
          />
        </div>
      </SimpleModal>
  }
}

const mapStateToProps = () => ({
})

export default connect(
  mapStateToProps,
  {
    renameFavourite: renameFavourite
  }
)(withSnackbar(RenameFavouriteModal))

import React, {Component} from 'react'
import {OptionMenu} from '../../components/OptionMenu'
import {config} from "../../../core/conf/config";
import './WorkoutMenu.scss'
import AddFavouriteModal from "../../components/favourites/AddFavouriteModal";

interface ComponentProps {
  workoutExpression: string
}
class WorkoutMenu extends Component<ComponentProps> {

  state = {
    favouriteModelOpen: false
  }

  shareLink = async () => {
    const {workoutExpression} = this.props
    if (!!navigator.share) {
      await navigator.share({
        title: 'Timer workout',
        text: 'Séance',
        url: `${config.baseUrl}/workout/${workoutExpression}`,
      })
    }
  }

  openFavouriteModal = () => {
    this.setState({favouriteModelOpen: true})
  }

  closeFavouriteModal = () => {
    this.setState({favouriteModelOpen: false})
  }


  render() {
    const {favouriteModelOpen} = this.state
    const {workoutExpression} = this.props

    const menuItems: any = [
      {
        action: this.openFavouriteModal,
        content: <div className="menu-item"><i className="fa fa-save" />Enregistrer la séance</div>
      },
      {
        action: this.shareLink,
        content: <div className="menu-item"><i className="fa fa-share-alt"></i>Partager</div>
      }
    ]
    return <>
      <AddFavouriteModal
        handleClose={this.closeFavouriteModal}
        open={favouriteModelOpen}
        currentWorkout={workoutExpression} />
      <OptionMenu
        menuItems={menuItems}
        buttonContent={<><i className="fa fa-ellipsis-v"></i> Options</>}
      />
    </>
  }
}
export default WorkoutMenu

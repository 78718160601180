import React, {Component} from 'react'
import {
  getPartialTimelineItemDisplayInfo,
  getTimelineIndex,
  TimelineItemInfoDisplayInfo
} from "../../../../store/workout/workoutSelector";
import {connect} from "react-redux";
import {Transition} from 'react-spring/renderprops'
import './Timeline.scss'

type TimelineProps = {
  showNextExercise?: boolean,
  // from state
  timeline: TimelineItemInfoDisplayInfo[],
  timelineIndex: number
}

/**
 * Affichage de l'exercise en cours et de son nom,
 * gère aussi l'animation de la passage d'un exercice à l'autre
 */
class Timeline extends Component<TimelineProps> {

  state = {
    sensNavigation: 1
  }

  componentDidMount() {
    // preload des images de la timeline
    const {timeline} = this.props
    timeline && timeline.forEach(item => {
      if (item?.img) {
        new Image().src = item?.img;
      }
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps: Readonly<TimelineProps>, nextContext: any) {
    if (nextProps.timelineIndex !== this.props.timelineIndex) {
      this.setState({
        sensNavigation: nextProps.timelineIndex - this.props.timelineIndex
      })
    }
  }

  render() {
    const {timeline, timelineIndex} = this.props
    const {sensNavigation} = this.state

    const nextIndex = timelineIndex + 1
    const nextExercise = timeline.find(t => t.id === nextIndex)

    const fromPosition = sensNavigation > 0 ? 'translate3d(50%,0,0)' : 'translate3d(-50%,0,0)'
    const leavePosition = sensNavigation < 0 ? 'translate3d(50%,0,0)' : 'translate3d(-50%,0,0)'
    return (<div className="timeline">
      {timeline.map((item, index) =>
        <Transition
          key={index}
          items={timelineIndex}
          unique
          reset
          from={{opacity: 0, transform: fromPosition}}
          enter={{opacity: 1, transform: 'translate3d(0,0,0)'}}
          leave={{opacity: 0, transform: leavePosition}}>
          {currentTimelineIndex =>
            (item.id === currentTimelineIndex) &&
            (props => <div style={props} className="exercise" key={timelineIndex}>
              {item?.img &&
              <div className="exercise-illustration">
                <img src={item.img} alt=""/>
              </div>
              }
              <div className={`exercise-title ${!item?.img && 'full'}`}>{item && item.label}</div>
            </div>)
          }
        </Transition>
      )}
      {
        nextExercise?.img && (
          <Transition
            items={!!this.props.showNextExercise}
            reset
            from={{opacity: 0}}
            enter={{opacity: 1}}
            leave={{opacity: 0}}
          >
            {
              show => show && nextExercise?.imgShadow &&
                (props =>
                    <div className="next-exercise-preview" style={props}>
                      <div className="exercise-illustration">
                        <img src={nextExercise.imgShadow} alt=""/>
                      </div>
                      <div className="next-exercise-title">Suivant</div>
                      <div className="next-exercise-preview-arrow"></div>
                    </div>
                )
            }
          </Transition>
        )
      }

    </div>)
  }

}

const mapStateToProps = (state: any) => ( {
  timeline: getPartialTimelineItemDisplayInfo
(
  state
),
  timelineIndex: getTimelineIndex
(
  state
)
}

)

export default connect(
mapStateToProps,
{
}
)(Timeline)

import React, { Component, Fragment } from "react"
import { withSnackbar } from "notistack"
import * as serviceWorkerRegistration from '../serviceWorkerRegistration'

class ServiceWorkerRegister extends Component<any, any> {
  private waitingWorker: any = {}

  componentDidMount() {
    serviceWorkerRegistration.register({
      onUpdate: this.onServiceWorkerUpdate
    })
  }

  onServiceWorkerUpdate = (registration: any) => {
    const { enqueueSnackbar } = this.props
    this.waitingWorker = registration && registration.waiting
    enqueueSnackbar("Une nouvelle version est disponible", {
      persist: true,
      variant: "success",
      action: <Fragment>
        <button className="snackbar-button" onClick={this.updateServiceWorker}>
          Rafraîchir
        </button>
      </Fragment>,
    })
  }

  updateServiceWorker = () => {
    this.waitingWorker && this.waitingWorker.postMessage({ type: "SKIP_WAITING" })
    window.location.reload()
  }

  render() {
    return <></>
  }
}
export default withSnackbar(ServiceWorkerRegister) //uses the snackbar context

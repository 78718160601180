import React, {Component} from 'react'
import {Field, InjectedFormProps, reduxForm} from "redux-form";
import {ReduxTextField} from "../form/ReduxTextField";
import './AddFavouriteForm.scss'


const validate = (values: any): any => {
  let errors: any = {}
  if (!values.name) {
    errors.name = 'Champ obligatoire'
  }
  return errors
}

export interface EditFavouriteProps {
  buttonValiderContent: Element,
  onCancel: () => void
}

export interface EditFavouriteFormProps {
  name: string
}

class EditFavouriteForm extends Component<EditFavouriteProps & InjectedFormProps<EditFavouriteFormProps, EditFavouriteProps>> {
  render() {
    const {handleSubmit, onCancel, submitting, valid, buttonValiderContent} = this.props
    return <form onSubmit={handleSubmit}>
      <div className="favourite-form-fields-container">
        <Field name="name"
               type="text"
               label="Nom de la séance"
               placeholder=""
               maxLength={250}
               component={ReduxTextField}/>
      </div>
       <div className="favourite-form-submit-container">
         <button type="button" className="square-btn grey" onClick={onCancel}>
           Annuler
         </button>
         <button type="submit"  className="square-btn green" disabled={submitting || !valid}>
           {buttonValiderContent}
         </button>
       </div>
    </form>
  }
}

export default reduxForm<any, any>({
  form: 'AddFavouriteForm',
  validate
})(EditFavouriteForm)

import React, {Component} from 'react'
import './NavigationBottomBar.scss'
import {Link} from 'react-router-dom'
import {getCurrentWorkout} from "../../../store/workout/workoutSelector";
import {connect} from "react-redux";
import WorkoutMenu from "./WorkoutMenu";

interface NavigationBottomBarProps {
  workoutExpression: string | null
}

class NavigationBottomBar extends Component<NavigationBottomBarProps> {
  render() {
    const {workoutExpression} = this.props

    return (
      <nav className="navigation-bottom-bar" role="menu">
        <div className="menu-item-list">
          <div role="menuitem" className="menu-item menu-item-home">
            <Link to="/">
              <i className="fas fa-home" aria-hidden="true"></i> Accueil
            </Link>
          </div>
          <div role="menuitem" className="menu-item menu-item-seances">
            <Link to="/favourites">
              <i className="fas fa-list" aria-hidden="true"></i> Mes séances
            </Link>
          </div>
          {
            workoutExpression ?
              <>
                <div role="menuitem" className="menu-item menu-item-edit-seance">
                  <Link to={`/workout/edit?workout=${workoutExpression}`}>
                    <i className="fa fa-edit" aria-hidden="true"></i>  Modifier
                  </Link>
                </div>
                <div role="menuitem" className="menu-item menu-item-menu">
                  <WorkoutMenu workoutExpression={workoutExpression} />
                </div>
              </>
            : <div role="menuitem" className="menu-item menu-item-new-seance">
              <Link to="/workout/edit">
                <i className="fa fa-plus" aria-hidden="true"></i> Nouvelle séance
              </Link>
            </div>
          }
        </div>
      </nav>
    )
  }
}


const mapStateToProps = (state: any) => ({
  workoutExpression: getCurrentWorkout(state),
})

export default connect(mapStateToProps, {})
(NavigationBottomBar)

import React, {Component} from 'react'
import {connect} from "react-redux";
import {getFavourites} from "../../../../store/favourites/favouritesSelector";
import {Favourite} from "../../../../store/favourites/favourites";
import {Link} from 'react-router-dom'
import './FavouritesList.scss'
import {OptionMenu} from "../../../components/OptionMenu";
import {removeFavourite} from "../../../../store/favourites/favouritesActions";
import RenameFavouriteModal from './RenameFavouriteModal'

class FavouritesList extends Component<any> {

  state = {
    openFavouriteModal: false,
    favourite: null
  }

  removeFavourite = (favourite: Favourite) => {
    this.props.removeFavourite(favourite)
  }

  handleOpenFavouriteModal = (favourite: Favourite) => {
    this.setState({
      openFavouriteModal: true,
      favourite
    })
  }

  handleCloseFavouriteModal = () => {
    this.setState({
      openFavouriteModal: false
    })
  }

  render() {
    const {favourites} = this.props
    const {openFavouriteModal, favourite} = this.state

    if (!favourites || favourites.length === 0) {
      return <div className="empty-seance-list">
        <p>Vous n'avez aucune séance personnalisée.</p>
        <p>Pour en créer une, cliquez sur <Link to="/workout/edit">
            nouvelle séance
          </Link> et ajoutez la à vos séances.</p>
      </div>
    }
    return (
      <>
        <ul className="favourites-list">
          {favourites.map((f: Favourite, index: number) =>
            <li key={index}>
              <Link to={`/workout/${f.workoutExpression}`} className="favourites-list-item-name">
                {f.name}
              </Link>
              <OptionMenu className="favourites-list-item-button" menuItems={
                [
                  {
                    action: () => {
                      this.handleOpenFavouriteModal(f)
                    },
                    content: <div className="menu-item">
                      <i className="fa fa-edit"></i>Renommer
                    </div>
                  },
                  {
                    action: () => {
                      this.removeFavourite(f)
                    },
                    content: <div className="menu-item">
                      <i className="fa fa-times"></i>Supprimer
                    </div>
                  }
                ]
              } buttonContent={<i className="fa fa-ellipsis-h"></i>}/>
            </li>
          )
          }
        </ul>
        <RenameFavouriteModal
          handleClose={this.handleCloseFavouriteModal}
          open={openFavouriteModal}
          favourite={favourite} />
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  favourites: getFavourites(state)
})

export default connect(
  mapStateToProps,
  {
    removeFavourite: removeFavourite
  }
)(FavouritesList)


import React, {Component} from 'react'
import Workout from './components/Workout'
import NavigationBottomBar from "../../layout/bottomBar/NavigationBottomBar";

let wakeLock: any = null
const requestWakeLock = async () => {
  try {
    wakeLock = await (navigator as any).wakeLock.request('screen');
  } catch (err) {
    console.log(`${err.name}, ${err.message}`);
  }
}

const releaseWakeLock = async () => {
  try {
    await wakeLock?.release('screen');
  } catch (err) {
    console.log(`${err.name}, ${err.message}`);
  }
}

export default class RunWorkoutPage extends Component<any> {
  componentDidMount() {
    requestWakeLock()
  }

  componentWillUnmount() {
    releaseWakeLock()
  }

  render() {
    const workoutExpression = this.props?.match?.params?.workout
    const urlSearchParam = new URLSearchParams(this.props?.location?.search)
    const theme = urlSearchParam?.get('theme')
    const index: number = +(urlSearchParam?.get('index') ?? 0)
    return (
      <div className={`theme-${theme}`}>
        <NavigationBottomBar />
        <Workout workoutExpression={workoutExpression} index={index} />
      </div>
    )
  }
}

import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom'
import WorkoutPage from './ui/pages/workout/WorkoutPage'
import HomePage from './ui/pages/home/HomePage'
import Error404Page from './ui/pages/error/Error404Page'
import CreationSeancePage from "./ui/pages/creation/CreationSeancePage";
import ShareStravaWorkoutPage from "./ui/pages/workout/ShareStravaWorkoutPage";
import FavouritesPage from "./ui/pages/favourites/FavouritesPage";

export class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route path="/workout/edit" component={CreationSeancePage} />
        <Route path="/workout/strava/:workout" component={ShareStravaWorkoutPage} />
        <Route path="/workout/:workout" component={WorkoutPage} />
        <Route path="/favourites" component={FavouritesPage} />
        <Route path="/">
          <HomePage/>
        </Route>
        <Route path="*">
          <Error404Page/>
        </Route>
      </Switch>
    )
  }
}

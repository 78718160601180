import {Exercise, Series} from "../types/workout.d.";

type TokenType = 'SERIES' | 'NUMBER' | 'REST' | 'EFFORT' | 'REPETITION' | 'TYPE' | 'LINEBREAK'

export class ParsingError extends Error {
  tokensExpected: TokenType[]
  position: number

  constructor(message: any, tokensExpected: TokenType[], position: number) {
    super(message);
    this.tokensExpected = tokensExpected
    this.position = position
  }
}

interface Token {
  type: TokenType,
  position: number,
  value?: string
}

export const tokenizeString = (input: string, index = 0): Token[] => {

  if (!input || index >= input.length) {
    return []
  }

  const nextChar = input[index]
  if (nextChar.match(/[0-9]/)) {
    let token = ''
    let i = index
    for (; i < input.length && input[i].match(/[0-9]/); i++) {
      token += input[i]
    }
    return [
      {type: 'NUMBER', value: token, position: i},
      ...tokenizeString(input, i)
    ]
  }

  let newToken: Token
  switch (nextChar) {
    case 's':
      newToken = {type: 'SERIES', position: index}
      index++
      break
    case 'r':
    case 'R':
      newToken = {type: 'REST', position: index}
      index++
      break
    case 'n':
      newToken = {type: 'REPETITION', position: index}
      index++
      break
    case 'e':
      newToken = {type: 'EFFORT', position: index}
      index++
      break
    case 't':
      let i = index + 1
      let typeValue = ''
      for (; i < input.length && (input[i] !== '\n' && input[i] !== '-'); i++) {
        typeValue += input[i]
      }
      newToken = {type: 'TYPE', value: typeValue, position: index}
      index = i
      break
    case '\n':
    case '-':
      newToken = {type: 'LINEBREAK', position: index}
      index++
      break
    default:
      throw new Error(`Token inconnu : ${nextChar}`)
  }

  return [
    newToken,
    ...tokenizeString(input, index)
  ]
}

// s4R60
// 1e40r15
// 3e40r15t01
// s2R60
// 1e40r12

export const parseWorkout = (expression: string): Series[] => {
  const tokens = tokenizeString(expression)
  return parseWorkoutExpression(tokens)
}

export const parseWorkoutExpression = (tokens: Token[]): Series[] => {
  let series: Series[] = []
  while (tokens.length) {
    series = [
      ...series,
      ...parseSeriesExpression(tokens)
    ]
  }
  return series
}


const parseSeriesExpression = (tokens: Token[]): Series[] => {
  let series: Series[] = []

  let nextToken: Token | null = getNextTokenExpectingType(tokens, 'SERIES')
  while (nextToken && nextToken.type === 'SERIES') {
    tokens.shift()
    const nbSeriesToken = getNextTokenExpectingType(tokens, 'NUMBER')
    const nbSeries = +(nbSeriesToken.value ?? 0)
    tokens.shift()
    let currentToken = getNextToken(tokens)
    let rest = 0
    if (currentToken?.type === 'REST') {
      tokens.shift()
      const currentToken = getNextTokenExpectingType(tokens, 'NUMBER')
      rest = +(currentToken.value ?? 0)
    }
    tokens.shift()
    shiftLinebreaks(tokens)
    const exercises = parseExerciseListExpression(tokens)
    for (let i = 0; i < nbSeries; i++) {
      series.push({
        rest,
        exercises
      })
    }
    nextToken = getNextToken(tokens)
  }

  return series
}

const parseExerciseListExpression = (tokens: Token[]): Exercise[] => {
  let exercises: Exercise[] = []
  let nextToken = getNextToken(tokens)

  while (nextToken) {
    switch (nextToken.type) {
      case "NUMBER":
        tokens.shift()
        const nbExercise = +(nextToken.value ?? 0)
        let exercise = parseExerciseExpression(tokens)
        for (let i = 0; i < nbExercise; i++) {
          exercises.push({
            ...exercise
          })
        }
        nextToken = getNextToken(tokens)
        break
      case "EFFORT":
        exercises.push(parseExerciseExpression(tokens))
        nextToken = getNextToken(tokens)
        break
      case "REPETITION":
        exercises.push(parseExerciseExpression(tokens))
        nextToken = getNextToken(tokens)
        break
      case "LINEBREAK":
        shiftLinebreaks(tokens)
        nextToken = getNextToken(tokens)
        break
      default:
        return exercises
    }
  }
  return exercises
}

const parseExerciseExpression = (tokens: Token[]): Exercise => {
  const token = getNextTokenExpectingTypes(tokens, ['EFFORT', 'REPETITION'])
  tokens.shift()
  const effortTimeToken = getNextTokenExpectingType(tokens, 'NUMBER')
  const repetitions = token.type === 'REPETITION' ? +(effortTimeToken.value ?? 0) : null
  const effort = token.type === 'EFFORT' ? +(effortTimeToken.value ?? 0) : null
  tokens.shift()
  let currentToken = getNextToken(tokens)
  let rest = 0
  if (currentToken?.type === 'REST') {
    tokens.shift()
    currentToken = getNextTokenExpectingType(tokens, 'NUMBER')
    rest = +(currentToken.value ?? 0)
    tokens.shift()
    currentToken = getNextToken(tokens)
  }
  if (currentToken && currentToken.type === 'TYPE') {
    tokens.shift()
    const idType = currentToken.value
    shiftLinebreaks(tokens)
    return {
      effort,
      repetitions,
      rest,
      type: idType
    }
  }
  shiftLinebreaks(tokens)
  return {
    effort,
    repetitions,
    rest
  }
}


const getNextToken = (tokens: Token[]): Token | null => {
  return !tokens.length ? null : tokens[0]
}

const getNextTokenExpectingTypes = (tokens: Token[], types: TokenType[]): Token => {
  const nextToken = getNextToken(tokens)
  if (!nextToken || !types.find(t => nextToken.type === t)) {
    throw new ParsingError(
      `Expected ${types.join(' | ')} but found ${JSON.stringify(nextToken)}, 
    remaining tokens = ${JSON.stringify(tokens)}`,
      types, nextToken?.position ?? -1)
  }
  return nextToken
}

const getNextTokenExpectingType = (tokens: Token[], type: TokenType): Token => {
  return getNextTokenExpectingTypes(tokens, [type])
}

const shiftLinebreaks = (tokens: Token[]) => {
  let nextToken = getNextToken(tokens)
  while (nextToken && nextToken.type === 'LINEBREAK') {
    tokens.shift()
    nextToken = getNextToken(tokens)
  }
}

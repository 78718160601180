import {Favourite} from "../store/favourites/favourites";

export const storeFavourites = (favourites: Favourite[]) => {
    return localStorage.setItem('favourites', JSON.stringify(favourites))
}

export const loadFavourites = (): Favourite[] => {
  const localStorageValue = localStorage.getItem('favourites')
  const favourites: Favourite[] = localStorageValue ? JSON.parse(localStorageValue) : []
  return favourites
}
